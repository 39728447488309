import { useState, useEffect } from 'react';
import { Heading, Button, Notification } from '@auspost/postmaster-react';
import styles from './ProductAdd.module.scss';
import ProductDetail, { emptyProduct, isValidProduct } from '../components/organisms/ProductDetail';
import portalClient from '../utils/PortalClient';
import { useNavigate } from 'react-router-dom';

const ProductAdd = ({ partner }: { partner: any }) => {
    const [editingProduct, setEditingProduct] = useState(emptyProduct);
    const [invalidFields, setInvalidFields] = useState<string[]>([]);
    const navigate = useNavigate();

    const doCancel = () => {
        setEditingProduct(emptyProduct);
        navigate("/product-definition/update-product");
    }

    const doSave = async () => {
        try {
            if (!isValidProduct(editingProduct, setInvalidFields))
                return;
            let response = await portalClient.post(`/api/portal/products`, editingProduct);
            console.log('product saved', response.data);
            alert('Product Saved');
            setEditingProduct({ ...emptyProduct, PartnerID: partner.PartnerID });
        }
        catch (err: any) {
            let errorMsg = err.response?.data?.ErrorMessage;
            alert(`Error Saving Product${errorMsg ? `: ${errorMsg}` : ". Please ensure all mandatory fields have been supplied"}`);
            console.log(`Error Saving Product`, err);
            console.log(editingProduct);
        }
    }

    useEffect(() => {
        setEditingProduct({ ...emptyProduct, PartnerID: partner?.PartnerID });
    }, [partner?.PartnerID]);                                                        

    return (
        <>
            <div className={`main container ${styles.productAdd}`}>
                {/*<Heading level="h1">New Product Definition</Heading>*/}
                <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                New Product
                            </Heading>
                            <div className="actions">
                                <Button size="small" variant="primary" onClick={doSave}>Save</Button>
                                <Button size="small" variant="secondary" onClick={doCancel}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                    {invalidFields?.length > 0 &&
                        <div className="table-content">
                            <div className='flex-container row fields'>
                                <Notification isDismissible={false} className='flex-cell-fill' style={{ marginBottom: "1em" }}
                                    message={<>Missing required fields: {invalidFields.join(", ")}</>} status="error" type="alert" />
                            </div>
                        </div>}
                    <div className="table-content">
                        <div className="flex-container row fields">
                            <ProductDetail partner={partner} showChanges={false} product={editingProduct} setProduct={setEditingProduct} />
                        </div>                        
                        <div className="flex-container row fields actions">
                            <Button size="small" variant="primary" onClick={doSave}>Save</Button>
                            <Button size="small" variant="secondary" onClick={doCancel}>Cancel</Button>
                        </div>
                        {invalidFields?.length > 0 &&
                            <div className='flex-container row fields'>
                                <Notification isDismissible={false} className='flex-cell-fill' style={{ marginTop: "-1em" }}
                                    message={<>Missing required fields: {invalidFields.join(", ")}</>} status="error" type="alert" />
                            </div>}                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductAdd;
